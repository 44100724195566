import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerThree from '../../components/banner/BannerThree';
import HomeThreeService from '../../components/home-three/HomeThreeService';
import AboutFive from '../../components/about/AboutFive';
import HomeThreeCourse from '../../components/home-three/HomeThreeCourse';
import AboutSix from '../../components/about/AboutSix';
import FooterOne from '../../common/footer/FooterOne';
import FreeConsultation from '../innerpages/FreeConsultation';
import TestimonialSectionOne from '../../components/testimonial-section/TestimonialSectionOne';

const HomeThree = () => {
    return (
        <>
            <SEO title="FETC" />
             
            <HeaderTwo styles="header-transparent header-style-2" />

            <BannerThree />
            
            <HomeThreeService />

            <AboutFive />

            <HomeThreeCourse />
            
            <AboutSix />

            <TestimonialSectionOne />
            {/* <TestimonialSectionTwo /> */}

            <FreeConsultation />

            {/* <HomeThreeEvent /> */}

            {/* <NewsLetterFormTwo /> */}

            <FooterOne />
        </>
    )
}

export default HomeThree;