import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const PrForm = () => {

    const sendPrDetails = () => {
        //to do Api call
    }
    return (
        <>
            <SEO title="Pr Form" />
            <Layout>
                <BreadcrumbOne
                    title="Pr Form"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Pr form"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <h4 className="title">Please Fill Up Details For PR</h4>
                                <h5 className="title">1. Applicant’s detail</h5>
                                <form className={'rnt-contact-form rwt-dynamic-form row'} action="" onSubmit={sendPrDetails}>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="firstName">First Name</label>
                                            <input type="text" className="form-control form-control-lg" id="firstName" name="firstname" placeholder="First Name*" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="lastName">Last Name</label>
                                            <input type="text" className="form-control form-control-lg" name="lastname" id="lastName" placeholder="Last Name*" required />
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="exampleInputDate1">Date Of Birth</label>
                                            <input type="date" class="form-control form-control-lg" id="exampleInputDate1" name="date"/>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="birthPlace">Birth Place</label>
                                            <input type="text" className="form-control form-control-lg" id="birthPlace" name="birthplace" placeholder="Birth Place*" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-8">
                                        <div className="form-group">
                                        <label for="address">Current Address with Pin-code</label>
                                            <textarea className="form-control" name="address" id="address" placeholder="Current Address with Pin-code" required></textarea>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="mobileNo">Mobile No</label>
                                            <input type="text" className="form-control form-control-lg" name="mobile" id='mobileNo' placeholder="Mobile number" required/>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="workNo">Work No (Optional)</label>
                                            <input type="text" className="form-control form-control-lg" name="mobile" id='workNo' placeholder="Work / Office number" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="address">Email Id</label>
                                            <input type="email" className="form-control form-control-lg" name="email" placeholder="Email Id*" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="passportNumber">Passport Number</label>
                                            <input type="text" className="form-control form-control-lg" name="passportnumber" id="passportNumber" placeholder="passport number*" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="placeOfIssue">Place of Issue</label>
                                            <input type="text" className="form-control form-control-lg" name="placeofIssue" id="placeOfIssue" placeholder="passport number*" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="issueDate">Passport Issue Date</label>
                                            <input type="date" className="form-control form-control-lg" id="issueDate" name="birthplace" placeholder="Birth Place*" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="expiryDate">Passport Expiry Date</label>
                                            <input type="date" className="form-control form-control-lg" id="expiryDate" name="birthplace" placeholder="Birth Place*" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="currentResidence">Current country of residence</label>
                                            <input type="text" className="form-control form-control-lg" name="placeofIssue" id="currentResidence" placeholder="Current country of residence*" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="currentResidence">Your Status in that country</label>
                                            <input type="text" className="form-control form-control-lg" name="placeofIssue" id="countryStatus" placeholder="Your Status in that country*" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label for="floatingSelectGrid">Current Marital Status</label>
                                            <select class="form-select form-select-lg mb-3" id="floatingSelectGrid" aria-label="Floating label select example">
                                                <option selected>Current Marital Status</option>
                                                <option value="1">Single</option>
                                                <option value="2">Married</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                        <label for="expiryDate">Date of Marriage</label>
                                            <input type="date" className="form-control form-control-lg" id="marriageDate" name="birthplace" placeholder="Date of Marriage*" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <textarea className="form-control" name="message" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <button className="rn-btn edu-btn w-100" type="submit">
                                            <span>Submit Now</span><i className="icon-arrow-right-line-right"></i></button>
                                    </div>
                                    {/* { result ? <div className="form-group"><Result /></div>  : null } */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </Layout>
        </>
    )
}
export default PrForm;