import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';

const VisitorVisa = () => {
    return (
        <>
            <SEO title="Visitor Visa" />
            <Layout>
                <BreadcrumbOne 
                    title="Visitor Visa"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Visitor Visa"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50 text-center">
                                    <img className="radius-small w-100" src="/images/others/visitorVisa.jpg" alt="Privacy Policy" />
                                    <h2 className="title">~ Visitor Visa ~</h2>
                                </div>
                                
                                <p>A Visitor Visa is your ticket to explore new countries, meet loved ones, or attend important events abroad. Whether you want to experience the rich cultural heritage of Europe, visit family in the USA, or attend a business meeting in Australia, a Visitor Visa is essential for short-term stays.</p>
                                <h4 className="title">Why Choose a Visitor Visa?</h4>
                                <ul>
                                    <li><strong>Travel & Tourism:</strong> Discover new destinations, experience different cultures, and create lifelong memories.</li>
                                    <li><strong>Family Visits:</strong> Spend time with family and friends living abroad, attend weddings, reunions, or other important occasions.</li>
                                    <li><strong>Business Opportunities:</strong> Attend conferences, meetings, or explore business opportunities in international markets.</li>
                                    <li><strong>Medical Treatment:</strong> Seek specialized medical care or undergo medical treatment in foreign countries.</li>
                                </ul>

                                <h4 className="title">Eligibility Criteria</h4>
                                <p>To obtain a Visitor Visa, you typically need:</p>
                                <ul>
                                    <li><strong>Valid Passport:</strong> Your passport must be valid for the duration of your stay.</li>
                                    <li><strong>Purpose of Visit:</strong> Clear and legitimate reasons for visiting, such as tourism, family visit, or business.</li>
                                    <li><strong>Proof of Funds:</strong> Evidence that you can financially support yourself during your stay without working.</li>
                                    <li><strong>Ties to Home Country:</strong> Demonstrating strong ties to your home country, such as employment, property, or family, to ensure you’ll return after your visit.</li>
                                </ul>

                                <h4 className="title">Common Visitor Visa Requirements</h4>
                                <ul>
                                    <li><strong>Valid Passport:</strong> Ensure your passport is valid for at least six months beyond your intended stay.</li>
                                    <li><strong>Visa Application Form:</strong> Completed and signed application form, as per the guidelines of the destination country.</li>
                                    <li><strong>Proof of Travel:</strong> Documents like travel itineraries, flight bookings, and accommodation details.</li>
                                    <li><strong>Financial Evidence:</strong> Bank statements or proof of financial stability to cover your expenses during your stay.</li>
                                    <li><strong>Invitation Letter:</strong> If visiting family or friends, an invitation letter from your host may be required.</li>
                                </ul>

                                <h4 className="title">Our Services</h4>
                                <p>We assist students in navigating the complex Student Visa application process, ensuring all documents are prepared accurately and submitted on time. Our services include:</p>
                                <ul>
                                    <li><strong>Visa Consultation:</strong> Personalized advice tailored to your study destination and academic goals.</li>
                                    <li><strong>Document Preparation:</strong> Guidance on gathering and preparing all required documents.</li>
                                    <li><strong>Application Submission:</strong> Support in completing and submitting your visa application.</li>
                                    <li><strong>Interview Preparation:</strong> Tips and mock interviews to help you feel confident during your visa interview.</li>
                                    <li><strong>Photographs:</strong> Passport-sized photos as per the country’s specifications.</li>
                                </ul>
                            
                                <div className="eduvibe-contact-me-top edu-contact-me-area about-me-1 edu-section-gap bg-color-white">
                                    <div className="container eduvibe-animated-shape">
                                        <div className="row g-5">
                                            <div className="col-lg-6">
                                                <div className="pr--75 pr_lg--0 pr_md--0 pr_sm--0">
                                                    <div className="thumbnail">
                                                        <img className="w-100" src="/images/contact/contact-me/contact-1.jpg" alt="Contact Me" />
                                                        <div className="content-overlay">
                                                            <h4 className="title">Don’t Be Hesitate To <br /> Contact With US</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="section-title text-start mb--45">
                                                    <span className="pre-title">Contact Us</span>
                                                    <h3 className="title">Send Us Your Requirement</h3>
                                                </div>
                                                <ContactMeForm formStyle="rnt-contact-form rwt-dynamic-form row" />
                                            </div>
                                        </div>

                                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                            <div className="shape-image shape-image-1">
                                                <img src="/images/shapes/shape-03-11.png" alt="Shape Thumb" />
                                            </div>
                                            <div className="shape-image shape-image-2">
                                                <img src="/images/shapes/shape-15-06.png" alt="Shape Thumb" />
                                            </div>
                                            <div className="shape-image shape-image-3">
                                                <img src="/images/shapes/shape-09-03.png" alt="Shape Thumb" />
                                            </div>
                                            <div className="shape-image shape-image-4">
                                                <img src="/images/shapes/shape-03-02.png" alt="Shape Thumb" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default VisitorVisa;