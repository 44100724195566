import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const Vision = () => {
    return (
        <>
            <SEO title="Vision" />
            <Layout>
                <BreadcrumbOne 
                    title="Vision"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Vision"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50 text-center">
                                    <h2 className="title">" Help People Fulfill Their Dreams "</h2>
                                </div>
                                
                                <p className="text-center">At SyZyGy, we are dedicated to helping individuals achieve their dreams of studying, working, and living abroad. We provide a range of services designed to support you on your journey to success. From language proficiency exams to visa assistance, our comprehensive offerings ensure that you have the tools and guidance needed to reach your goals.</p>
            
                                <h4 className="title">Our Services Include:</h4>
                                <ol>
                                    <li> <strong> IELTS, TOEFL, and Other Exam Preparation </strong></li>
                                    <ol type="I">
                                        <li>We offer tailored training programs to help students and professionals pass exams like IELTS, TOEFL, PTE-YL, PTE-A, GRE, GMAT, and SAT.</li>
                                        <li>Our expert instructors provide personalized coaching and resources to maximize your chances of success.</li>
                                    </ol>

                                    <li> <strong>Discount Vouchers for IDP-IELTS</strong></li>
                                    <ol type="I">
                                        <li>As part of our commitment to making your journey affordable, we provide exclusive discount vouchers for IDP-IELTS exams.</li>
                                        <li>This ensures you can access the tests you need at a reduced cost.</li>
                                    </ol>
                                    <li><strong>Visa Assistance</strong></li>
                                    <ol type="I">
                                        <li>We guide you through the complex visa application process, whether you are applying for a visitor visa, student visa, or work permit.</li>
                                        <li>Our experienced team ensures that your application is accurate, complete, and submitted on time, increasing your chances of approval.</li>
                                    </ol>
                                    <li><strong>Study Abroad Support</strong></li>
                                    <ol type="I">
                                        <li>We help students find the best educational institutions abroad that match their academic goals and aspirations.</li>
                                        <li>Our team assists with everything from application submission to securing accommodation, ensuring a smooth transition to your new educational environment.</li>
                                    </ol>
                                    <li><strong>Work Permit Services</strong></li>
                                    <ol type="I">
                                        <li>We assist professionals in obtaining work permits for various countries, helping them to pursue career opportunities abroad.</li>
                                        <li>Our services include document preparation, application submission, and follow-up to ensure a successful outcome.</li>
                                    </ol>
                                </ol>

                                <h4 className="title">E-Learning Platform: SyZyGy</h4>
                                <ol>
                                    <li><strong>Improve Your English Skills</strong></li>
                                    <ol type="I">
                                        <li>Our E-Learning platform, SyZyGy, is designed to enhance your English language skills, preparing you for exams and real-world communication.</li>
                                        <li>Access interactive lessons, practice tests, and expert advice anytime, anywhere.</li>
                                    </ol>

                                    <li><strong>Exam-Specific Training</strong></li>
                                    <ol type="I">
                                        <li>PTE-YL, IELTS, PTE-A, TOEFL, GRE, GMAT, SAT: Our platform offers focused training for each of these exams, with modules that address specific challenges and common pitfalls.</li>
                                        <li>Regular practice tests and feedback sessions help you track your progress and adjust your learning strategy.</li>
                                    </ol>
                                </ol>

                                <h4 className="title">Why Choose Us? </h4>
                                <ol>
                                    <li><strong>Expert Guidance:</strong> Our team of seasoned professionals provides personalized support throughout your journey.</li>
                                    <li><strong>Comprehensive Services:</strong> From exam preparation to visa assistance, we cover all aspects of your path to success.</li>
                                    <li><strong>Affordable Options:</strong> Benefit from discount vouchers and competitive pricing on all our services.</li>
                                    <li><strong>Proven Success:</strong> Join thousands of satisfied clients who have successfully achieved their dreams with our help.</li>
                                </ol>

                                <p>Start your journey today with <strong>SyZyGy</strong> and turn your dreams into reality!</p>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Vision;