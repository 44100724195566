import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const RusticPath = () => {
    return (
        <>
            <SEO title="Privacy Policy" />
            <Layout>
                <BreadcrumbOne 
                    title="Rustic Pathways"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Rustic Pathways"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50">
                                    <h2 className="title">Rustic Pathways</h2>
                                    {/* <img className="radius-small w-100" src="/images/others/privacy-policy.jpg" alt="Privacy Policy" /> */}
                                </div>
                                
                                <h4 className="title">About Rustic Pathways</h4>
                                <p>Rustic Pathways is honored to be a finalist for this year’s International Travel Awards. The International Travel Awards recognize excellence in the travel industry across various categories, including hotels, resorts, tour operators, travel agencies, and airlines. The aim of the awards is to promote quality and innovation, enhancing global standards and customer experiences in the travel sector.
                                    The International Travel Awards recognize Rustic’s commitment to providing transformative travel experiences for students, prioritizing safety, cultural immersion, and positive impact. The group acknowledges Rustic Pathways’s efforts to set the highest standards in student travel, reflecting our dedication to quality, innovation, and the overall excellence of our programs.
                                    Join Rustic Pathways as we continue to lead and inspire in the world of student travel.</p>

                                    <button 
                                        className="rn-btn edu-btn w-100 mb--30" 
                                        type="button"
                                        onClick={() => window.open('https://rusticpathways.com', '_blank')}>
                                        <span>Click Here For Online Registration</span>
                                    </button>
            
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default RusticPath;