import React from 'react';
import Slider from 'react-slick';

let data = [
    // {
    //     name: "Geraldine D. Anspach",
    //     details: "Lorem ipsum dolor sit amet, consectetur dloril adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
    //     details2: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore. deserunt mollit anim id est laborum.",
    //     designation: "SEO Expert",
    //     image: "client-01.png",
    //     rating: 5
    // }
    {
        name: "Kajal Bothra",
        details: "I am an employee of FETC, and my visa application was successfully approved under the guidance of Bhumika Mam. This experience has not only strengthened my confidence but also equipped me with valuable insights into the embassy process, which I can now use to guide our clients effectively. I am grateful for the support and guidance throughout this process.",
        designation: "Visa Category: USA B1/B2 Visa",
        image: "testimonial1.png",
        rating: 5
    },
    {
        name: "Jigar Shah & Family",
        details: "Thank you, FETC, for your incredible support! Special thanks to you for keeping me positive and calm during the process of obtaining visas for me and my family. Your guidance and encouragement made a significant difference. Thanks again to the entire team, especially Bhumika, for their exceptional assistance.",
        designation: "Visa Category: Australia Visitor Visa",
        image: "testimonial2.png",
        rating: 5
    },
    {
        name: "Dharmendra Lad & Family",
        details: "We are delighted to inform you that we have successfully obtained our visa! Thank you so much for your invaluable guidance and support throughout the process. Securing the earliest visa slot and assisting us with the visa documentation made the entire process remarkably smooth. Your preparation for the visa interview was incredibly helpful, and we truly appreciate your expertise and dedication. The best part is that I will now be able to attend my child's convocation. Thank you FETC for everything.",
        designation: "Visa Category: USA B1/B2 Visa ",
        image: "testimonial3.png",
        rating: 5
    },
    {
        name: "Prajal Sonariya",
        details: "FETC has been instrumental in shaping my profile, allowing me to focus on honing my musical skills with minimal effort on my part. Their comprehensive support included SOP writing, preparing financial documents, conducting visa mock interviews, and even securing visa dates. Thanks to their assistance, I successfully gained admission and obtained my visa. I am extremely grateful for their help.",
        designation: "Visa Category: USA Student Visa Approved",
        image: "testimonial4.png",
        rating: 5
    },
    {
        name: "Dhaval Pokiya, Jenika Pokiya & Shriyan Pokiya",
        details: "I, Dhaval Pokiya, want to thank you for successfully attaining USA tourist visas for my children, and ours earlier. After my experience, I am confident that FETC can handle any visa queries and ensure a smooth process. We are so happy to be able to travel to the USA as a family!",
        designation: "Visa Category: USA B1/B2 Visa",
        image: "testimonial5.png",
        rating: 5
    },
    {
        name: "Shaswat Maheshwari",
        details: "I am thrilled to share that I have successfully obtained my UK visitor visa to visit my sister. During this challenging era for visa approvals, I am especially grateful to FETC for their invaluable assistance in making the application process smooth and efficient. Thank you, FETC, for your support and expertise.",
        designation: "Visa Category: UK Visitor Visa",
        image: "testimonial6.png",
        rating: 5
    },
    {
        name: "Udit Gagnani",
        details: "I had an excellent experience with FETC From the start, they provided a comprehensive list of all required documents and clearly explained the entire application process. They also meticulously detailed each stamp needed to validate the documents for my visa application. Their step-by-step guidance and professional support made the complex process straightforward and stress-free. Their attention to detail and dedication ensured everything was in order, which gave me great confidence in my application. I highly recommend FETC for their exceptional and thorough service.",
        designation: "Visa Category: Fully Funded Free Education Italy Student Visa",
        image: "testimonial7.png",
        rating: 5
    },
    {
        name: "Samarth Pachchigar",
        details: "I couldn't be more satisfied. The overall process was incredibly smooth, thanks to their efficient and knowledgeable team. While visa processes are usually a hassle, fetc ensured everything went seamlessly for us. Their professionalism and attention to detail made a significant difference, and I highly recommend their services to anyone seeking a stress-free experience with their education abroad process.",
        designation: "Visa Category: Spain Student Visa",
        image: "testimonial8.png",
        rating: 5
    }
];

const PrevArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow prev-arrow slick-arrow" onClick={onClick}><i className="icon-arrow-left-line"></i></button>
}

const NextArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow next-arrow slick-arrow" onClick={onClick}><i className="icon-arrow-right-line"></i></button>
}

const TestimonialThree = ( props ) => {
    const sliderSettings = {
        dots: props.dots === "enable" ? true : false,
        infinite: true,
        arrows: props.arrows === "disable" ? false : true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: props.fade === "disable" ? false : true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    data = props.item ? data.slice( 0, props.item ) : data;
  
    const classes = `eduvibe-testimonial-three inner testimonial-card-activation-1 slick-arrow-style-2 ${props.className ? props.className : '' }`;
    return (
        <Slider 
            className={classes}
            { ...sliderSettings }
        >
            { data.map( ( item, i ) => (
                <div 
                    className={ props.itemClass || 'single-card' }
                    key={i}
                >
                    { props.rating !== 'disable' && 
                        <div className="rating eduvibe-course-rating-stars">
                            {[...Array(5)].map((_, index) => {
                                return <i key={index} className={ index < item.rating ? 'on icon-Star' : 'off icon-Star' }></i>
                            })}
                        </div>
                    }
                    <p className="description">“{ props.details2 !=="enable" ? item.details : item.details2 }”</p>
                    <div className="client-info">
                        <div className="thumbnail">
                            <img src={`${process.env.PUBLIC_URL}/images/testimonial/testimonial-04/${item.image}`} alt="Client Thumb" />
                        </div>
                        <div className="content">
                            <h6 className="title">{ item.name }</h6>
                            <span className="designation">{ item.designation }</span>
                        </div>
                    </div>
                </div>
            ) ) }
        </Slider>
    )
}

export default TestimonialThree;