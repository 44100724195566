import React from 'react';
import { Parallax } from 'react-scroll-parallax';

const AboutUsThreeMission = () => {
    return (
        <div className="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="thumbnail mb--50">
                            <div className="shape-image image-1">
                                <Parallax speed={1} y={[0, 40]} tagOuter="figure">
                                    <img src="/images/mission/Vertical-1.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-2">
                                <Parallax speed={1} y={[0, -20]} tagOuter="figure">
                                    <img src="/images/mission/Horizontal-1.jpeg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-3">
                                <Parallax speed={1} y={[0, 30]} tagOuter="figure">
                                    <img src="/images/mission/Vertical-2.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="shape-image image-4">
                                <Parallax speed={1} y={[0, -18]} tagOuter="figure">
                                    <img src="/images/mission/Horizontal-2.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="animated-image shape-image-1">
                        <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                    <div className="shape shape-2">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6">
                        <div className="our-mission">
                            <div className="section-title text-start">
                                <span className="pre-title">Our Mission</span>
                                <h3 className="title">The Mission Behind <br /> Education Platform</h3>
                                <p className="mt--30 mb--0">Foreign English Test Capital is an exam center powered by
                                    Gina Abroad Pvt. Ltd. FETC is the acronym that is used to
                                    address the center.
                                    FETC, is an approved exam center for both PSI online
                                    (ETS) and also Language Cert (LC) to conduct various level
                                    exams of SELT (Skills for English Language Tests) UKVI.
                                    FETC has proven to be a signature test center with the most
                                    number of exam stations to conduct the exams at a time for
                                    many students, work permit holders & spouses travelling to
                                    UK. </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="our-vision">
                            <div className="section-title text-start">
                                <span className="pre-title">Our Vision</span>
                                <h3 className="title">The Story of Our <br /> Founders</h3>
                                <p className="mt--30 mb--0">FETC is a visionary project by Ms. Dilkhush who has been
                                    a pioneer in introducing ESL programs in Surat since 2012.
                                    Her experience in the ESL industry and being professionally
                                    assessed as an assessor of PTE (YLE & Cambridge) have
                                    helped many children excel in their English Language Skills
                                    to meet their future goal either to study abroad or carve a
                                    niche in the career in their home country itself. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutUsThreeMission;