import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="">
                <Link to="/home-3">Home</Link>
            </li>

            <li className="has-droupdown">
                        <Link to="#">About</Link>
                        <ul className="submenu">
                            <li><Link to="/vision">Vision</Link></li>
                            <li><Link to="/about-us">About Us</Link></li>
                        </ul>
            </li>
            
            <li className="has-droupdown">
                <Link to="#">Services</Link>
                <ul className="submenu">
                <li className="has-droupdown">
                        <Link to="#">Seasonal Programs</Link>
                        <ul className="submenu">
                            <li><Link to="/rustic-path">Rustic Pathways</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/course-details-three/1">Study Abroad</Link></li>
                    <li className="has-droupdown">
                        <Link to="#">Visa</Link>
                        <ul className="submenu">
                            <li><Link to="/visitor-visa">Visitors</Link></li>
                            <li><Link to="/student-visa">Students</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/pr_Form">PR</Link></li>
                    <li><Link to="/coming-soon">Air tickets</Link></li>
                    <li className="has-droupdown">
                        <Link to="#">Examinations</Link>
                        <ul className="submenu">
                            <li><Link to="/coming-soon">LCCI</Link></li>
                            <li><Link to="/coming-soon">PTE-Versant</Link></li>
                            <li><Link to="/pte-general">PTE General</Link></li>
                        </ul>
                    </li>
                    <li className="has-droupdown">
                        <Link to="#">Training</Link>
                        <ul className="submenu">
                            <li><Link to="/coming-soon">PTE- YL</Link></li>
                            <li><Link to="/coming-soon">IELTS</Link></li>
                            <li><Link to="/coming-soon">PTE-A</Link></li>
                            <li><Link to="/coming-soon">TOEFL</Link></li>
                            <li><Link to="/coming-soon">GRE/GMAT/SAT</Link></li>
                        </ul>
                    </li>
                </ul>
                {/* <ul className="submenu">
                    <li><Link to="/course-1">Course 1</Link></li>
                    <li><Link to="/course-2">Course 2</Link></li>
                    <li><Link to="/course-3">Course 3</Link></li>
                    <li><Link to="/course-4">Course 4</Link></li>
                    <li><Link to="/course-5">Course 5</Link></li>
                    <li><Link to="/course-details/2">Course Details 1</Link></li>
                    <li><Link to="/course-details-two/1">Course Details 2</Link></li>
                    <li><Link to="/course-filter-1">Course Filter 1</Link></li>
                    <li><Link to="/course-filter-2">Course Filter 2</Link></li>
                    <li><Link to="/course-filter-sidebar">Course Filter Sidebar</Link></li>
                    <li><Link to="/course-carousel">Course Carousel</Link></li>
                    <li><Link to="/course-load-more">Course( Load More )</Link></li>
                    <li><Link to="/course-category/business">Course Category Archive</Link></li>
                </ul> */}
            </li>

            <li className="has-droupdown">
                        <Link to="#">Support</Link>
                        <ul className="submenu">
                            <li><Link to="/helpline">Helpine Number/ Virtual Guide</Link></li>
                            <li><Link to="/blog-grid-1">Blogs</Link></li>
                            <li><Link to="/testimonial">Testimonials</Link></li>
                            <li><Link to="/gallery-masonry">Gallery</Link></li>
                            <li><Link to="/contact-me">Contact Us</Link></li>
                        </ul>
            </li>
            
            <li className="has-droupdown">
                <Link to="#">E-learning</Link>
                <ul className="submenu">
                <li><Link to="/coming-soon">SyZyGy</Link></li>
                {/* <li><Link to="/course-5">SyZyGy</Link></li> */}
                    
                </ul>
            </li>
            
            {/* <li className="has-droupdown"><Link to="#">Blog</Link>
                <ul className="submenu">
                    <li><Link to="/blog-grid-1">Blog Grid 1</Link></li>
                    <li><Link to="/blog-grid-2">Blog Grid 2</Link></li>
                    <li><Link to="/blog-grid-3">Blog Grid 3</Link></li>
                    <li><Link to="/blog-carousel">Blog Carousel</Link></li>
                    <li><Link to="/blog-standard">Blog Standard</Link></li>
                    <li><Link to="/blog-grid-right-sidebar">Blog( Right Sidebar )</Link></li>
                    <li><Link to="/blog-grid-left-sidebar">Blog( Left Sidebar )</Link></li>
                    <li><Link to="/blog-load-more">Blog( Load More )</Link></li>
                    <li><Link to="/blog-details/1">Blog Details 1</Link></li>
                    <li><Link to="/blog-details-left-sidebar/1">Blog Details 2</Link></li>
                    <li><Link to="/blog-details-right-sidebar/1">Blog Details 3</Link></li>
                    <li><Link to="/category/education">Category Archive</Link></li>
                    <li><Link to="/tag/finance">Tag Archive</Link></li>
                    <li><Link to="/author/hazel-thomas">Author Archive</Link></li>
                </ul>
            </li> */}

            {/* <li className="">
                <Link to="/contact-me">Contact Us</Link>
            </li> */}
            {/* <li className="has-droupdown">
                <Link to="/">Contact</Link>
                <ul className="submenu">
                    <li><Link to="/contact-me">Contact Me</Link></li>
                    <li><Link to="/contact-us">Contact Us</Link></li>
                </ul>
            </li> */}
        </ul>
    )
}
export default Nav;
