import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import SectionTitle from '../sectionTitle/SectionTitle';

const AboutUsThreeInstructor = () => {
    const [visible, setVisible] = useState( 'team3' );
    return (
        
        <div className="eduvibe-about-three-team edu-instructor-area edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = "Team Member"
                            title = "Meet Our Instructor"
                        />
                    </div>
                </div>
                <div className="row g-5 mt--20">
                            
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team1' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team1' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'James Carlson' ) }`}>
                                                    <img src="/images/instructor/instructor-03/member-1.jpg" alt="team images" />
                                                </Link>
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title"><Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Bhumika Dilkhush' ) }`}>Bhumika Dilkhush</Link></h5>
                                                <span className="desc">Director</span>
                                                <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team2' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team2' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Selina Velkani' ) }`}>
                                                    <img src="/images/instructor/instructor-03/member-2.jpg" alt="team images" />
                                                </Link>
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title"><Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Selina Velkani' ) }`}>Selina Velkani</Link></h5>
                                                <span className="desc">Application Head</span>
                                                <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team3' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team3' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Kajal Bothra' ) }`}>
                                                    <img src="/images/instructor/instructor-03/member-3.jpg" alt="team images" />
                                                </Link>
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title"><Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Kajal Bothra' ) }`}>Kajal Bothra</Link></h5>
                                                <span className="desc">Head of Accounts</span>
                                                <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team4' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team4' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Jainuddin Shaikh' ) }`}>
                                                    <img src="/images/instructor/instructor-03/member-4.jpg" alt="team images" />
                                                </Link>
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title"><Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Jainuddin Shaikh' ) }`}>Jainuddin Shaikh</Link></h5>
                                                <span className="desc">Language Trainer</span>
                                                <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team5' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team5' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Ashish Yadav' ) }`}>
                                                    <img src="/images/instructor/instructor-03/member-5.jpg" alt="team images" />
                                                </Link>
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title"><Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Ashish Yadav' ) }`}>Ashish Yadav</Link></h5>
                                                <span className="desc">WordPress Expert</span>
                                                <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team6' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team6' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Allarakha Pathan' ) }`}>
                                                    <img src="/images/instructor/instructor-03/member-6.jpg" alt="team images" />
                                                </Link>
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title"><Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Allarakha Pathan' ) }`}>Allarakha Pathan</Link></h5>
                                                <span className="desc">Web Developer</span>
                                                <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team7' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team7' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Zohra Visani' ) }`}>
                                                    <img src="/images/instructor/instructor-03/member-7.jpg" alt="team images" />
                                                </Link>
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title"><Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Zohra Visani' ) }`}>Zohra Visani</Link></h5>
                                                <span className="desc">UI Designer</span>
                                                <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={`edu-instructor-grid edu-instructor-3 edu-instructor-3-visible${ visible === 'team8' ? ' eduvibe-hover-active' : '' }`} onMouseOver={ () => setVisible( 'team8' ) }>
                                    <div className="edu-instructor">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Karim Visani' ) }`}>
                                                    <img src="/images/instructor/instructor-03/member-3.jpg" alt="team images" />
                                                </Link>
                                            </div>
                                            <div className="edu-instructor-info">
                                                <h5 className="title"><Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( 'Karim Visani' ) }`}>Karim Visani</Link></h5>
                                                <span className="desc">Digital Marketer</span>
                                                <div className="team-share-info bg-transparent">
                                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-25-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-03.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-15-05.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsThreeInstructor;