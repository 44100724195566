import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const items = [
    {
        title: 'Personal Development',
        numberOfCourses: 'Personal Development: Unlock Your Potential to Succeed',
        icon: 'icon-Schoolbag',
        link: '#'
    },
    {
        title: 'Tips & Tricks',
        numberOfCourses: 'To help you prepare fast, we offer plenty of tips and techniques.',
        icon: 'icon-student-read',
        link: '#'
    },
    {
        title: 'Study Materials',
        numberOfCourses: 'All of our in-class material is covered in four updated IELTS books.',
        icon: 'icon-Pencil',
        link: '#'
    },
    {
        title: 'Mock tests',
        numberOfCourses: 'The best way to boost your score is by practicing on our simulated test engine.',
        icon: 'icon-Class',
        link: '#'
    },
    {
        title: 'Precise grading',
        numberOfCourses: 'You can forecast your exam-day result with the aid of our accurate mock test grading.',
        icon: 'icon-Setting',
        link: '#'
    },
    {
        title: 'Lifestyle',
        numberOfCourses: 'Elevate Your Lifestyle with Daily Tips for a Healthier, Happier You.',
        icon: 'icon-Mind',
        link: '#'
    },
    // {
    //     title: 'Digital Marketing',
    //     numberOfCourses: '15 Courses',
    //     icon: 'icon-Headphone',
    //     link: '#'
    // },
    // {
    //     title: 'Data Sciences',
    //     numberOfCourses: '30 Courses',
    //     icon: 'icon-Microscope',
    //     link: '#'
    // },
    // {
    //     title: 'Health & Fitness',
    //     numberOfCourses: '22 Courses',
    //     icon: 'icon-Fitness',
    //     link: '#'
    // }
];

const CategoryThree = ( { wrapperClass, styleClass } ) => {
    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` }>
            { items.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-4 col-md-6 col-12 ${ styleClass ? styleClass : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className="service-card service-card-5">
                        <div className="inner">
                            <div className="icon">
                                <i className={ data.icon }></i>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.numberOfCourses }</p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default CategoryThree;